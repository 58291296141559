<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Statistieken</h1>
        
        <b-tabs>
            <b-tab title="Verkocht">
                <b-card class="mb-3"></b-card>
            </b-tab>
            <b-tab title="Gescand">
                <b-card class="mb-3"></b-card>
            </b-tab>
        </b-tabs>

        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5 mb-0">Verkoop op basis van dag en tijd</h3>
            </b-card-header>
            <b-card-body>
                
            </b-card-body>
        </b-card>


        <b-tabs>
            <b-tab title="Per uur">
                <b-card class="mb-3" no-body>
                    <b-card-header>
                        <h3 class="fs-5 mb-0">Webshop bezoekers</h3>
                    </b-card-header>
                    <b-card-body>
                        
                    </b-card-body>
                </b-card>
            </b-tab>
            <b-tab title="Per dag">
                <b-card class="mb-3" no-body>
                    <b-card-header>
                        <h3 class="fs-5 mb-0">Webshop bezoekers</h3>
                    </b-card-header>
                    <b-card-body>
                        
                    </b-card-body>
                </b-card>
            </b-tab>
            <b-tab title="Per maand">
                <b-card class="mb-3" no-body>
                    <b-card-header>
                        <h3 class="fs-5 mb-0">Webshop bezoekers</h3>
                    </b-card-header>
                    <b-card-body>
                        
                    </b-card-body>
                </b-card>
            </b-tab>
        </b-tabs>
        
        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5 mb-0">Tickets</h3>
            </b-card-header>
            <b-card-body>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Ticket</th>
                            <th>Verkocht</th>
                            <th>Gasten</th>
                            <th>Totaal</th>
                            <th>Beschikbaar</th>
                            <th>Gescand</th>
                        </tr>
                    </thead>
                    <tbody>

                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Totaal</th>
                            <th>0</th>
                            <th>0</th>
                            <th>0</th>
                            <th>0</th>
                            <th>0</th>
                        </tr>
                    </tfoot>
                </table>
            </b-card-body>
        </b-card>

        <b-row>
            <b-col cols="6">
                <b-card class="mb-3" no-body>
                    <b-card-header>
                        <h3 class="fs-5 mb-0">Leeftijden</h3>
                    </b-card-header>
                    <b-card-body>

                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="6">
                <b-card class="mb-3" no-body>
                    <b-card-header>
                        <h3 class="fs-5 mb-0">Geolocatie</h3>
                    </b-card-header>
                    <b-card-body>
                        
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

      </b-container>
    </div>
  </template>
  
  <script>
    export default {
        metaInfo: {
            title: 'Statistieken',
        },
        components: {},
        data() {
            return {
                event: {},
            }
        },
        computed: {},
        methods: {
            fetchEvent: function() {
                let id = this.$route.params.id;
                this.$axios.get("https://api.tixgo.nl/events/" + id)
                    .then( response => {
                        if (response.data) {
                            this.event = response.data;
                        }
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
        },
        created() {
            this.fetchEvent();
        },
    }
</script>